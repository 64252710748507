import { Component, Vue } from 'vue-property-decorator'
import Alert from '@/components/shared/Alert.vue'
import Confirm from '@/components/shared/Confirm/confirm.vue'
import { ValidationObserver } from 'vee-validate'
import { DocumentAdminService } from '@/services/patient-document-admin-service'
import { allReportType } from '../Prescriptions/Constants'
import { DocumentAdminInfoResponse } from '@/models/document-admin-model'
import { dossierPatientModel } from '@/models/dossier-response-model'
import Commons from '@/components/shared/Helpers/commons'
import { ErrorService } from '@/services/error.service'
import DocumentList from '@/components/shared/DocumentList/DocumentList.vue'
import { PatientService } from '@/services/patient-service'

@Component({
  components: {
    Alert,
    Confirm,
    ValidationObserver,
    DocumentList
  }
})
export default class DocumentAdmin extends Vue {
  private readonly documentAdminService = DocumentAdminService.getInstance()
  private patientService = PatientService.getInstance()

  public documentAdminCategories = allReportType
  public dossierId: string|null = null
  public apiURL = process.env.VUE_APP_API_URL

  public headersDocument: any[] = []

  public initLoading = false
  public documents: DocumentAdminInfoResponse[] = []
  public documentsClone: DocumentAdminInfoResponse[] = []
  public errorMessages: string[] = []
  private filterId!: number | undefined

  public async mounted () {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this
    this.headersDocument = [
      {
        text: 'Type',
        value: 'typeId',
        formatter: this.getCategoryNameById,
        sort: function (a, b) {
          return that.getCategoryNameById(a)!.localeCompare(that.getCategoryNameById(b)!)
        }
      },
      { text: 'Date de création', value: 'dateCreated', formatter: Commons.TransformDateFormat },
      { text: 'Créateur', value: 'createdBy' },
      { text: 'Date de prescription', value: 'datePrescription', formatter: Commons.TransformDateFormat },
      { text: 'Médecin', value: 'medecinNames' },
      { text: '', value: 'actions', sortable: false, align: 'right' }
    ]
    if (this.$route.params.id) {
      await this.patientService.getDossierPatientById(this.$route.params.id)
        .then((data: dossierPatientModel) => {
          sessionStorage.setItem('selectedDossier', JSON.stringify(data))
        })
        .catch(async (errs) => {
          const res = await ErrorService.handleError(errs)
          this.errorMessages = res.errors
        })
    }
    const cachedDossier = sessionStorage.getItem('selectedDossier')
    if (Commons.isCachedDossierPatientValid(cachedDossier)) {
      const selectedDossier = JSON.parse(cachedDossier!) as dossierPatientModel
      Commons.updateDossierName()
      this.dossierId = selectedDossier.guid
      this.getDocuments()
    } else {
      this.$router.push({ name: 'patient' })
    }
  }

  private getDocuments () {
    if (!this.dossierId) return
    this.initLoading = true
    this.documentAdminService.getPatientDocumentAdmin(this.dossierId)
      .then(documents => {
        this.documents = documents
        this.documentsClone = [...documents]
        this.filterByCategory(this.filterId)
      })
      .catch(async (errs) => {
        const res = await ErrorService.handleError(errs)
        this.errorMessages = res.errors
      })
      .finally(() => {
        this.initLoading = false
      })
  }

  public getCategoryNameById (categoryId: number) {
    return this.documentAdminCategories.find(x => x.value === categoryId)?.text
  }

  public filterByCategory (event?: number) {
    this.filterId = event
    if (!event) {
      this.documents = [...this.documentsClone]
    } else {
      const filterresults = this.documentsClone.filter(x => x.typeId === event)
      this.documents = filterresults
    }
  }

  public async doDelete (document: DocumentAdminInfoResponse) {
    const results = await this.documentAdminService.deletePatientDocumentAdmin(document.id)
      .catch(async (errs) => {
        const res = await ErrorService.handleError(errs)
        this.errorMessages = res.errors
      })
    if (results === 200) {
      this.errorMessages = []
      this.getDocuments()
    }
  }

  public hideAlert () {
    this.errorMessages = []
  }
}
